
import {Options, Vue} from 'vue-class-component';
import Menu from "@/components/Menu.vue";

@Options({
  components: {
    Menu
  },
  methods:{
    isMobile() {
      return screen.width <= 760;
    }
  }
})
export default class Home extends Vue {

}
