import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import 'bootstrap/dist/js/bootstrap.bundle.min.js'
import VueGtag from "vue-gtag-next";

createApp(App)
    .use(router)
    .use(VueGtag, {
        property: {
            id: "G-PVE1KBPW52"
        }
    })
    .mount('#app')
